import FormInput from "components/functional/FormInput/FormInput";
import { FormInputProps } from "types/common";
export const AssetInput: React.FC<FormInputProps> = ({
  label,
  error,
  containerStyle,
  inputStyle,
  labelStyle,
  register,
  ...inputProps
}) => {
  return (
    <FormInput
      labelStyle={" text-primary"}
      containerStyle={"!w-full md:!w-3/12 text-primary mx-4"}
      label={label}
      inputStyle={"shadow-[#0042e4_1px_1px_0px_0px]"}
      error={error}
      register={register}
      {...inputProps}
    />
  );
};
