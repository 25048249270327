import React, { FC } from "react";
import HeatmapGrid from "react-heatmap-grid";
import { useWidth } from "hooks/useWidth";

export const Heatmap: FC<HeatmapProps> = ({
  xLabels,
  yLabels,
  data,
  background = "#329fff",
  height = 40,
  onClick,
  squares = false,
  xLabelWidth = 100,
  yLabelWidth = 100,
  yLabelTextAlign = "right",
  xLabelsLocation = "bottom",
  xLabelsVisibility,
  unit,
  cellRender,
  title,
}) => {
  const {width}= useWidth();

  const cellStyle = (
    background: string,
    value: number,
    min: number,
    max: number,
    data: HeatmapData[][],
    x: number,
    y: number
  ): React.CSSProperties => {
    const colorScale = ["#69BBD1", "#E7D4BE", "#F1B5AA","#DFA2B3", "#FF5733"];
    // Calculate the risk factor
    const riskFactor = (value - min) / (max - min);
    // Calculate the index for the color scale based on the risk factor
    const index = Math.floor(riskFactor * (colorScale.length - 1));
    const color = colorScale[index];
    let celWidth = '25px'
    if(width > 500) celWidth = '50px';
    if(width > 1395) celWidth = '100px';
    if(width > 1510) celWidth = '125px';
    if(width > 1639) celWidth = '150px';
    return {
      background: color,
      fontSize: "11.5px",
      color: "#000",
      width: celWidth,
    };
  
  };

  const renderText = (value: number | null): string => {
    return value !== null ? `${value}` : "";
  };

  return (
    <div className="mt-10">
      <HeatmapGrid
        data={data}
        xLabels={xLabels}
        yLabels={yLabels}
        background={background}
        height={height}
        onClick={onClick}
        squares={squares}
        xLabelWidth={xLabelWidth}
        yLabelWidth={yLabelWidth}
        yLabelTextAlign={yLabelTextAlign}
        xLabelsLocation={xLabelsLocation}
        xLabelsVisibility={xLabelsVisibility}
        unit={unit}
        cellRender={renderText}
        cellStyle={cellStyle}
        title={title}
      />
    </div>
  );
};
