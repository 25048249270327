import Divider from "components/ui/Divider";
import React, { PropsWithChildren } from "react";
import { IimageWrapper } from "types/common";
import { twMerge } from "tailwind-merge";
import { IcoAppremonLogo } from "../../assets/svg/Icons/icons";

export const ImageWrapper: React.FC<PropsWithChildren<IimageWrapper>> = ({
  children,
  name,
  title,
  image,
  dividerStyle,
  containerClass = "",
  titlemeta,
}) => {
  return (
    <div className={twMerge("m-7 relative", containerClass)}>
      <div className="bg-primary flex  py-2 w-1/4 m-auto absolute right-[38%] bottom-[96%] rounded-lg md:block">
        <div className="flex justify-center gap-3">

        <IcoAppremonLogo />
        <Divider color="white" height="30px" isVertical/>
        {!!name && <h1 className="text-2xl font-bold text-white ">{name}</h1>}
        </div>
      </div>

      <div className="border border-primary min-h-[90vh] rounded-xl">
        {!!image && <img src={image} className=" h-44 w-full rounded-t-xl" />}

        <div className="py-2">
          <p className=" font-subtitle1 text-secondary_blue text-center">
            {titlemeta}
          </p>
          {!!title && (
            <p className=" font-title text-primary text-center">{title}</p>
          )}
          <Divider
            color="#0042e4"
            width="40%"
            height="2px"
            style={{ ...(dividerStyle && dividerStyle), margin: "auto" }}
          />
        </div>
        {children}
      </div>
    </div>
  );
};
