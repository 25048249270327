import { GoSignOut } from "react-icons/go";
import { LuLayoutDashboard, LuUsers } from "react-icons/lu";
import { SiAwsorganizations } from "react-icons/si";
import { Link } from "react-router-dom";
import { useLogoutUser } from "services/auth/auth.query";
export const AdminSideBar: React.FC = () => {
  const {mutate: logoutMutation} = useLogoutUser();
  return (
    <aside
      id="logo-sidebar"
      className="fixed top-0 left-0 z-40 w-64 h-screen pt-20 transition-transform -translate-x-full bg-white border-r border-gray-200 sm:translate-x-0 dark:bg-gray-800 dark:border-gray-700"
      aria-label="Sidebar"
    >
      <div className="h-full px-3 pb-4 overflow-y-auto bg-white dark:bg-gray-800">
        <ul className="space-y-2 font-medium">
          <li>
            <Link
              to="/super_admin/dashboard"
              className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
            >
              <LuLayoutDashboard />
              <span className="ms-3">Dashboard</span>
            </Link>
          </li>
          <li>
            <Link
              to="/super_admin/organizations"
              className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
            >
             <SiAwsorganizations />
              <span className="flex-1 ms-3 whitespace-nowrap">Organizations</span>
            </Link>
          </li>
          <li>
            <Link
              to="/super_admin/organization/ORG-0"
              className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
            >
              <LuUsers />
              <span className="flex-1 ms-3 whitespace-nowrap">Users</span>
            </Link>
          </li>

          <li>
            <div
              className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group cursor-pointer"
              onClick={()=> logoutMutation()}
            >
              <GoSignOut />
              <span className="flex-1 ms-3 whitespace-nowrap">Sign Out</span>
            </div>
          </li>
        </ul>
      </div>
    </aside>
  );
};
