import React from "react";
function getFirstChildId(children: React.ReactNode): string | undefined {
  const childId: string | undefined = React.Children.map(
    children,
    (child): string | undefined => {
      if (React.isValidElement(child) && child.props.id) {
        return child.props.id;
      }
      return undefined;
    }
  )?.find(Boolean);

  return childId;
}

const formatApiError = (error: any) => {
  if (!error?.response?.data?.detail) {
    return '';
  }

  const erroDeetail = error.response.data.detail;
  const formattedError = typeof erroDeetail === 'string' ? erroDeetail?.replace(/_/g, ' ')?.toLowerCase() : '';

  return formattedError.charAt(0).toUpperCase() + formattedError.slice(1);
};

const getToken = ()=>{
  const token =   localStorage.getItem("access_token");
  const type = localStorage.getItem("token_type");

  return token && type ? `${type} ${token}` : '';
}


export { getFirstChildId, formatApiError, getToken };
