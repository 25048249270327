import { createContext, useContext, useState, useReducer } from "react";
import { WIZARD_CONTEXT_ACTIONS } from "constants/wizardActions";
import { PropsWithChildren } from "react";
import { IWizardState } from "types/contexts";

type Action = {
  type: keyof typeof WIZARD_CONTEXT_ACTIONS;
  payload?: any;
};

const WizardContext = createContext<{
  state: IWizardState;
  dispatch: React.Dispatch<Action>;
} | null>(null);

export const useWizardContext = () => useContext(WizardContext)!;

const initialState: IWizardState = {
  activeIndex: 0,
  totalComponents: 4,
  isFirstPage: true,
  isLastPage: false,
  organization: {
    company_name: "",
    suite: "",
    state: "",
    postal_code: "",
    website: "",
    street_address: "",
    city: "",
    country: "",
    telephone: "",
  },
  sensorConfig: {
    cidr: "",
    excluded_ip: "",
    ip_address: "",
    is_dhcp: false,
    netmask: "",
    gateway: "",
    dns_address: "",
  },
};

const reducer = (state: IWizardState, action: Action): IWizardState => {
  switch (action.type) {
    case WIZARD_CONTEXT_ACTIONS.SET_ITEM: {
      const { key, value } = action.payload;
      return {
        ...state,
        [key]: value,
      };
    }
    case WIZARD_CONTEXT_ACTIONS.RESET_CONTEXT:
      return initialState;

    case WIZARD_CONTEXT_ACTIONS.NEXT_PAGE: {
      let newIndex = state.activeIndex;
      if (newIndex < state.totalComponents - 1) newIndex += 1;

      let isFirstPage = newIndex <= 0;
      let isLastPage = newIndex >= state.totalComponents - 1;
      return {
        ...state,
        activeIndex: newIndex,
        isFirstPage,
        isLastPage,
      };
    }
    case WIZARD_CONTEXT_ACTIONS.PREV_PAGE: {
      let newIndex = state.activeIndex;
      if (newIndex > 0) newIndex -= 1;
      let isFirstPage = newIndex <= 0;
      let isLastPage = newIndex >= state.totalComponents - 1;

      return {
        ...state,
        activeIndex: Math.max(newIndex, 0),
        isFirstPage,
        isLastPage,
      };
    }
    case WIZARD_CONTEXT_ACTIONS.GOTO_PAGE: {
      const { index } = action.payload;
      let isFirstPage = index <= 0;
      let isLastPage = index >= state.totalComponents - 1;

      return {
        ...state,
        activeIndex: index,
        isFirstPage,
        isLastPage,
      };
    }
    default:
      return state;
  }
};

export const WizardContextProvider: React.FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <WizardContext.Provider value={{ state, dispatch }}>
      {children}
    </WizardContext.Provider>
  );
};
