import Wrapper from "components/hoc/Wrapper";
import { Gauge } from "components/Charts/Guage/Guage";
import { Heatmap } from "components/Charts/Heatmap/Heatmap";
import { DashboardOverview } from "./Overview/DashboardOverview";
import { RiskAssesment } from "./RiskAssesment/RiskAssesment";
import { DashboardSensors } from "./Sensors/DashboardSensors";

const Dashboard: React.FC = () => {
  return (
    <Wrapper containerClass={'p-2'}>
      <DashboardOverview />
      <RiskAssesment />
      <DashboardSensors/>
    </Wrapper>
  );
};

export default Dashboard;
