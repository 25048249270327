import FormInput from "components/functional/FormInput/FormInput";
import { FormInputProps } from "types/common";
export const AssetInput: React.FC<FormInputProps> = ({
  label,
  error,
  containerStyle,
  inputStyle,
  labelStyle,
  register,
  ...inputProps
}) => {
  return (
    <FormInput
      labelStyle={" font-label"}
      containerStyle={"!w-[250px] "}
      label={label}
      inputStyle={"shadow-[#0042e4_1px_1px_0px_0px]"}
      error={error}
      register={register}
      {...inputProps}
    />
  );
};
