import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import React from "react";
import { ColDef } from "ag-grid-community";
import { IOrganization } from "types/common";
import "./table.scss";
interface IOrganizationTable {
  rowData: IOrganization[];
  setRowData: (value: IOrganization[]) => void;
  columnDefs: ColDef[];
  handleRowClicked?: (e: any) => void;
}
const OrganizationTable: React.FC<IOrganizationTable> = ({
  rowData,
  setRowData,
  columnDefs,
  handleRowClicked,
}) => {
  return (
    <div className="ag-theme-alpine w-full">
      <AgGridReact
        columnDefs={columnDefs}
        rowData={rowData}
        pagination={true}
        domLayout="autoHeight"
        paginationPageSize={10}
        onRowClicked={(e) => handleRowClicked && handleRowClicked(e.data)}
        gridOptions={{
          autoSizeStrategy: {
            type: 'fitGridWidth',
            defaultMinWidth: 100,
            columnLimits: Object.keys(rowData[0]).map((key) => {
              return { colId: key, minWidth: 100 };
            }),
          },
        }}
      />
    </div>
  );
};

export default OrganizationTable;
