import React from "react";
import { CardContainer } from "components/ui/CardContainer";
import { SensorCard } from "./SensorCards";
import { useSensor } from "services/sensor/sensor.query";
import { PuffLoader } from "react-spinners";
interface Sensor {
  name: string;
  tag: string;
  zones: string[];
  status: string;
}

const sensors: Sensor[] = [
  {
    name: "Sensor-alpha",
    tag: "dmz, datacenter-a",
    zones: ["Atlanta-Net-1"],
    status: "Major",
  },
  {
    name: "Sensor-alpha",
    tag: "dmz, datacenter-a",
    zones: ["Atlanta-Net-1"],
    status: "Major",
  },
  {
    name: "Sensor-alpha",
    tag: "dmz, datacenter-a",
    zones: ["Atlanta-Net-1"],
    status: "Major",
  },
  {
    name: "Sensor-alpha",
    tag: "dmz, datacenter-a",
    zones: ["Atlanta-Net-1"],
    status: "Major",
  },
];

const columns = ["Name", "Tag", "Zones", "Status"];

export const DashboardSensors: React.FC = () => {
  const { data: sensors, isLoading, isError } = useSensor();

  if (isLoading)
    return <PuffLoader color="#0042e4" size={75} className=" mx-auto" />;
  if (isError)
    return (
      <h1 className="text-center text-red-500"> Failed to load sensors</h1>
    );

  return (
    <div className="mt-5">
      <h1 className="text-blue-600 text-lg font-bold mb-2">Sensors</h1>

      <div className="flex gap-2 xl:justify-between flex-wrap justify-center ">
        {sensors?.map((sensor) => (
          <CardContainer
            title="Sensors"
            redirectTo="/super_admin/dashboard"
            linkClass={"bg-gradient-to-r from-green-500 to-green-400"}
            containerClass={"!px-0 border-2 border-[#5FB082] rounded-2xl min-w-[400px]"}
          >
            <SensorCard
              sensor={sensor}
              columns={columns}
              headerCellClass={"border-[#5FB082]"}
            />
          </CardContainer>
        ))}
      </div>
    </div>
  );
};
