import loginImage from "assets/login.png";
import FormInput from "components/functional/FormInput/FormInput";
import Wrapper from "components/hoc/Wrapper";
import Divider from "components/ui/Divider";
// import useForm from "hooks/useForm";
import React from "react";
import { z } from "zod";
import styles from "./styles.module.scss";
import { useNavigate } from "react-router-dom";
import { AppremonBrand } from "assets/svg/Icons/icons";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useRegisterUser } from "services/auth/auth.query";
import { formatApiError } from "utils/common";
import { twMerge } from "tailwind-merge";
import {PuffLoader} from 'react-spinners'
const loginSchema = z
  .object({
    first_name: z
      .string()
      .min(3, { message: "First name must be atleast 3 letters" }),
    last_name: z
      .string()
      .min(3, { message: "Last name must be atleast 3 letters" }),
    email: z.string().email({ message: "Please enter a valid email" }),
    password: z
      .string()
      .min(6, { message: "Password must be atleast 6 letters" }),
    confirmPassword: z
      .string()
      .min(6, { message: "Password must be atleast 6 letters" }),
    areTermsAccepted: z.boolean().refine(value => value === true, {
      message: "Please accept terms to continue",
    }),
  })
  .superRefine((data, ctx) => {
    if (data.password !== data.confirmPassword) {
      ctx.addIssue({
        path: ["confirmPassword"],
        message: "Passwords do not match",
        code: "custom",
      });
    }
  });

type loginType = z.infer<typeof loginSchema>;

const Signup: React.FC = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    setError,
  } = useForm<loginType>({ resolver: zodResolver(loginSchema) });
  const {mutateAsync: registerUserMutation, isPending} = useRegisterUser();

  const registerUser = async (data: loginType) => {
    const {confirmPassword,areTermsAccepted, ...userData} = data;
    registerUserMutation(userData).catch((err)=>{
      setError('root', {type: 'manual', message: formatApiError(err) || 'An unexpected error occured'})
    });
  };



  const onError = () => {};
  return (
    <Wrapper containerClass={"p-10 pb-0"}>
      <form onSubmit={handleSubmit(registerUser)}>
        <div className="grid md:grid-cols-2 gap-4 border w-10/12 border-primary m-auto rounded-lg relative">
          <div className="flex flex-col justify-center items-center gap-3 p-10 md:p-0">
            <p className=" text-primary font-title">Sign Up</p>
            <Divider color="#7E9FEF" width="50%" height="2px" />

            <FormInput
              label="First Name"
              error={errors.first_name?.message}
              containerStyle=" md:!w-1/2"
              register={register("first_name")}
            />
            <FormInput
              label="Last Name"
              error={errors.last_name?.message}
              containerStyle=" md:!w-1/2"
              register={register("last_name")}
            />

            <FormInput
              label="Email Address"
              error={errors.email?.message}
              containerStyle=" md:!w-1/2"
              register={register("email")}
            />
            <FormInput
              label="Password"
              type="password"
              error={errors.password?.message}
              containerStyle={" md:!w-1/2"}
              register={register("password")}
            />
            <FormInput
              label="Confirm Password"
              type="password"
              error={errors.confirmPassword?.message}
              containerStyle={" md:!w-1/2"}
              register={register("confirmPassword")}
            />
            <div className="flex justify-between md:w-1/2">
              <p className={twMerge("text-md text-secondary_blue", errors?.areTermsAccepted && 'text-red-500' )}>
                I have read and accept T&C
              </p>
              <input
                type="checkbox"
                className={`form-checkbox h-5 w-5 !bg-red-500  input-checkbox ${styles["input-checkbox"]}`}
                {...register("areTermsAccepted")}
                
              />
            </div>

           
            <p className="text-red-500 text-xs h-3">{errors.root?.message}</p>
            
            <button
              type="submit"
              className="bg-gradient-to-r from-blue-600 to-secondary_skyblue text-white font-bold py-2 px-4 md:w-1/2 rounded-xl flex justify-center items-center gap-2" 
            >
              <PuffLoader loading={isPending} size={25} color="white"/>
              Register
            </button>

            <p className=" font-body text-start md:w-1/2 text-secondary_dark">
              Don't have an account yet?{" "}
              <span
                className="text-primary hover:underline hover:cursor-pointer font-medium"
                onClick={() => navigate("/")}
              >
                {" "}
                Sign In
              </span>
            </p>
          </div>
          <div className="h-fit relative hidden md:block">
            <img
              className=" h-[90vh] object-cover"
              src={loginImage}
              width={"100%"}
              height={"100vh"}
            />
             <div className="w-[79%] font-subtitle1 text-3xl absolute top-[35%] right-[10%] text-center text-primary opacity-55">
                <p>Welcome to Appremon.</p> Proactive Intelligent Remediation of
                Persistent Evolving Cyber Threats.
              </div>
          </div>

          <div className="bg-primary text-white text-center py-2 w-1/4 m-auto absolute right-[38%] bottom-[96%] rounded-lg flex justify-center">
            <AppremonBrand />
          </div>
        </div>
      </form>
    </Wrapper>
  );
};

export default Signup;
