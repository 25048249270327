import { PropsWithChildren, Suspense } from "react";
import { PERMISSIONS } from "enums/Permissions";
import { Route, Navigate } from "react-router-dom";
import SuperAdminLayout from "components/hoc/SuperAdminLayout";
import { getToken } from "utils/common";
import Wizard from "pages/Wizard/Wizard";
import AssetsPage from "pages/Assets/Assets";
import { Outlet } from "react-router-dom";
const Potected: React.FC = () => {
  const token = getToken();
  console.log(token, !token);
  // if (!token) return <Navigate to="/" />; //this is commented for now because it should be public for now

  return <Suspense fallback={<div>Loading...</div>}>{<Outlet />}</Suspense>;
};
const UserRoutes = () => {

  return [
    <Route path="/" element={<Potected />}>
      <Route path="/wizard" element={<Wizard />} />
      <Route path="/assets" element={<AssetsPage />} />
    </Route>,
  ];

};

export default UserRoutes;
