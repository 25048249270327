import axios from "config/axios.config";
import { IGetSensorResponse } from "types/service";



const getSensors = async () : Promise<IGetSensorResponse>=>{
    const response = await axios.get('/sensors/fastscan');

    return response.data;
}


export {getSensors};