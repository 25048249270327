import OrganizationTable from "./OrganizationTable";
import { ColDef } from "ag-grid-community";
import { useState, useEffect } from "react";
import { IOrganization } from "types/common";
import { useNavigate } from "react-router-dom";
const Organizations = () => {
  const [rowData, setRowData] = useState<IOrganization[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    // Generate dummy data
    const generateDummyData = () => {
      const dummyData: IOrganization[] = [];
      for (let i = 0; i < 100; i++) {
        dummyData.push({
          orgid: `ORG-${i}`,
          orgname: `Organization ${i}`,
          orgaddressone: `Address ${i}`,
          orgcity: `City ${i}`,
          orgstate: `State ${i}`,
          orgzip: `ZIP ${i}`,
          orgweb: `www.organization${i}.com`,
          orgcontactemail: `contact@organization${i}.com`,
        });
      }
      setRowData(dummyData);
    };

    generateDummyData();
  }, []);

  // Ag-Grid column definitions
  const columnDefs: ColDef[] = [
    { headerName: "ID", field: "orgid" },
    { headerName: "Name", field: "orgname" },
    { headerName: "Address", field: "orgaddressone" },
    { headerName: "City", field: "orgcity" },
    { headerName: "State", field: "orgstate" },
    { headerName: "ZIP", field: "orgzip" },
    { headerName: "Website", field: "orgweb" },
    { headerName: "Contact Email", field: "orgcontactemail" },
  ];

  const handleRowClicked = (organization: IOrganization)=>{
    navigate(`/super_admin/organization/${organization.orgid}`)
  }
  return (
    <div>
      <h1 className=" text-5xl font-bold mb-2 text-primary"> Appremon Organizations</h1>
      {rowData.length > 0 ? <OrganizationTable rowData={rowData} setRowData={setRowData} columnDefs={columnDefs} handleRowClicked={handleRowClicked} /> : <h1 className="text-primary text-center text-2xl font-semibold">Users Not Available</h1>}
    </div>
  );
};

export default Organizations;
