import assetsImage from "assets/assets.png";
import { ImageWrapper } from "components/hoc/ImageWrapper";
import React from "react";
import Divider from "components/ui/Divider";
import { Dropdown } from "components/Dropdowns/Dropdown";
import { useNavigate } from "react-router-dom";
import { WizardFormButtons } from "../WizardFormButtons";
const options = [
  { label: "mac", value: "mac" },
  { label: "windows", value: "windows" },
  { label: "linux", value: "linux" },
  { label: "ubunut", value: "ubuntu" },
];
const Download: React.FC = () => {

  const handleDownload = () => {

  }
  return (
    <React.Fragment>
      <div className="flex flex-col justify-between">
        <div className="flex gap-5 items-center justify-center flex-col md:flex-row">
          <div className="w-52">
            <Dropdown />
          </div>
          <Divider isVertical={true} color="#0042e4" height="50px" />
          <div className="w-11/12 md:w-1/4">
            <p className="text-primary font-subtitle2">Egress Note:</p>
            <p className="opacity-50 font-body text-secondary_dark">
              Please make sure you allow egress on the perimeter network from
              the sensos oc cusapi.appremon.ai on tep port 443
            </p>
          </div>
        </div>

        <WizardFormButtons nextButtonProps={{ onClick: handleDownload }} />
      </div>
    </React.Fragment>
  );
};

export default Download;
