import { useWizardContext } from "context/WizardContext"
import { twMerge } from "tailwind-merge";

interface IWFormButtons{
    nextButtonProps?:  Partial<React.HTMLAttributes<HTMLButtonElement>>;
    backButtonProps?: Partial<React.HTMLAttributes<HTMLButtonElement>>;
    containerClass? : string;
}

export const WizardFormButtons: React.FC<IWFormButtons> = ({nextButtonProps, backButtonProps, containerClass})=>{
    const {state, dispatch} = useWizardContext();
    const {isFirstPage, isLastPage} = state;
    return (
        <div className={twMerge("flex justify-between items-center px-10 absolute bottom-0 w-full",containerClass )}>
        <button
          onClick={()=> dispatch({type: 'PREV_PAGE'})}
          className={twMerge(" text-primary border border-primary w-36 px-5 py-2 m-1 rounded-xl", isFirstPage && 'cursor-not-allowed opacity-45')}
          disabled={isFirstPage}
          {...backButtonProps}
        >
          Back
        </button>
        <button
          type="submit"
          className="bg-gradient-to-r from-blue-400 to-primary text-white w-36 px-5 py-2 m-1 rounded-xl"
          {...nextButtonProps}
        >
          {isLastPage ? 'Download' : 'Next'}
        </button>
      </div>
    )
}