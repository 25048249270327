import AssetsPage from "pages/Assets/Assets";
import Login from "pages/Auth/Login/index";
import Signup from "pages/Auth/SignUp/Signup";
import Dashboard from "pages/Dashboard";
import WizardAssets from "pages/Wizard/Assets";
import WizardConfirmation from "pages/Wizard/Confirmation";
import WizardDownload from "pages/Wizard/Download";
import WizardNetworkInfo from "pages/Wizard/NetworkInfo";
import Wizard from "pages/Wizard/Wizard";
import { Route, Routes } from "react-router-dom";
import SuperAdminRoutes from "routes/SuperAdminRoutes";
import "./App.css";
import { PERMISSIONS } from "./enums/Permissions";
import NoAuthRoutes from "routes/NoAuthRoutes";
import UserRoutes from "routes/UserRoutes";
function App() {
  return (
    <div className="font-roboto">
      <Routes>
        <>
          {NoAuthRoutes()}
          {UserRoutes()}
          {SuperAdminRoutes([PERMISSIONS.SUPER_ADMIN], false)}
          <Route path="/*" element={<h1>Page Not found</h1>} />
        </>
      </Routes>
    </div>
  );
}

export default App;
