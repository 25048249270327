import axios from 'config/axios.config'
import { IRegisterPayload, ILoginPayload, ILoginRes } from 'types/service'
import { IUser } from 'types/entity'

const registerUser = async (payload : IRegisterPayload): Promise<IUser> =>{

    const res = await axios.post('/auth/register', {...payload, organization_id: '2cfb2479-ff92-408a-acda-332afffd4879'})

    return res.data;
}

const loginUser = async (payload: ILoginPayload):Promise<ILoginRes> =>{
    const res = await axios.post('/auth/login', payload, {headers: {'Content-Type': 'application/x-www-form-urlencoded'}})
    return res.data
}

const logoutUser = async ()=>{
    const res = await axios.post('/auth/logout')
    return res.data;
}



export {registerUser, loginUser, logoutUser};