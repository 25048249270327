import React from "react"
import Divider from "components/ui/Divider"
import { OverviewCard } from "./OverviewCard"

export const DashboardOverview: React.FC = ()=>{


    return <div className="">
        <h1 className=" text-blue-600 text-lg font-bold mb-2">Security Overview</h1>
        <div className="flex lg:justify-between justify-center  flex-wrap gap-y-4  ">
            <OverviewCard score="319" title="Total Assets" redirectTo="/assets"/>
            <OverviewCard score="319" title="Total Vulnerabilities" containerClass='from-[#DF7C89] to-[#F78669]' redirectTo="/super_admin/dashboard"/>
            <OverviewCard score="319" title="Exposure Score" containerClass='from-[#56ACA3] to-light-green' redirectTo="/super_admin/dashboard"/>
            <OverviewCard score="319" title="Zones Protected" containerClass='from-[#6198BF] to-blue-300' redirectTo="/super_admin/dashboard"/>
        </div>
    </div>
}