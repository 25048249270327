import { ImageWrapper } from "components/hoc/ImageWrapper";
import Assets from "./Assets";
import Confirmation from "./Confirmation";
import Download from "./Download";
import NetworkInfo from "./NetworkInfo";
import { useWizardContext } from "context/WizardContext";
import assetsImage from "assets/assets.png";

const wizardScreens = [
  <Assets />,
  <NetworkInfo />,
  <Confirmation />,
  <Download />,
];

const wizardScreenTitles = [
  "Organization Profile",
  "Sensor Config",
  "Confirmation",
  "Download",
];

const DividerWidth = ['29%', '21%', '20%', '15%'];
const Wizard = () => {
  const { state, dispatch } = useWizardContext();
  const { activeIndex, totalComponents } = state;
  return (
    <ImageWrapper
      title={wizardScreenTitles[activeIndex]}
      titlemeta={`wizard page ${activeIndex + 1} of ${totalComponents}`}
      name="Wizard"
      image={assetsImage}
      containerClass={"w-10/12 mx-auto my-12"}
      dividerStyle={{ width: DividerWidth[activeIndex] }}
    >
      {wizardScreens[activeIndex]}
    </ImageWrapper>
  );
};
export default Wizard;
