import assetsImage from "assets/assets.png";
import { ImageWrapper } from "components/hoc/ImageWrapper";
import React from "react";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { NetworkInput } from "./NetworkInput/NetworInput";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import FormField from "components/ui/FormField";
import { Input } from "components/ui/Input";
import { Toggle } from "components/ui/Toggle";
import { useWizardContext } from "context/WizardContext";
import { WizardFormButtons } from "../WizardFormButtons";

const ipAddressWithCidrRegex = /^(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\/(16|24))?$/;
const ipAddressRegex = /^(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)$/;
const cidrAddressRegex = /^(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)\/(16|24)$/;

const validateCommaSeparatedIPs = (value: string, regex: RegExp) => {
  return value.split(',').every(part => regex.test(part.trim()));
};

const assetsSchema = z
  .object({
    cidr: z.string().regex(cidrAddressRegex, {message: "please enter a valid CIDR"}),
    excluded_ip: z.string().refine(val => validateCommaSeparatedIPs(val, cidrAddressRegex), { message: "please enter valid IPs" }),
    ip_address: z.string().optional(),
    is_dhcp: z.boolean(),
    netmask: z.string().regex(ipAddressRegex, {message: "please enter a valid Netmask"}),
    gateway: z.string().regex(ipAddressRegex, {message: "please enter a valid Gateway"}),
    dns_address: z.string().refine(val => validateCommaSeparatedIPs(val, ipAddressRegex), { message: "enter valid DNS Addresses" }),
  })
  .superRefine((data, ctx) => {
    if (!data.is_dhcp && (!data.ip_address || !ipAddressRegex.test(data.ip_address))) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "please enter a valid IP",
        path: ["ip_address"],
      });
    }
  });

type assetType = z.infer<typeof assetsSchema>;
const NetworkInfo: React.FC = () => {
  const navigate = useNavigate();
  const {state, dispatch} = useWizardContext();
  const {sensorConfig}= state;

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<assetType>({ resolver: zodResolver(assetsSchema), defaultValues: sensorConfig });

  const handleFormSubmit = (data: assetType) => {
    dispatch({ type: "SET_ITEM", payload: { key: "sensorConfig", value: data } });
    dispatch({ type: "NEXT_PAGE" })
  };

  const handleBack = () => {
    navigate("/wizard/assets");
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-20 mt-5">
          <div className="flex flex-col items-start md:items-end gap-2 mt-3">
            <NetworkInput
              label="CIDR's to Scan (Comma Delimited)"
              error={errors.cidr?.message}
              placeholder="Ex: 10.1.50/24, 192.168.80.0/24"
              register={register("cidr")}
            />

            <NetworkInput
              label="Excluded IP's (Comma Delimited)"
              error={errors.excluded_ip?.message}
              placeholder="Ex: 10.1.50/24, 192.168.80.0/24"
              register={register("excluded_ip")}
            />
          </div>
          <div className="flex flex-col items-start gap-2">
            <FormField
              containerStyle={"!w-11/12 md:!w-8/12 text-label mx-4"}
              labelStyle={"text-label"}
              label="Enter Ip"
              error={errors.ip_address?.message}
            >
              <Toggle register={register("is_dhcp")} label="DHCP" />
              <Input
                id="ip_address"
                error={!!errors.ip_address?.message}
                className="shadow-[#0042e4_1px_1px_0px_0px] mt-1 h-8" 
                register={register("ip_address")}
                placeholder="Enter IP Address"
              />
            </FormField>

            <NetworkInput
              label="DNS Addresses (comma separated)"
              error={errors.dns_address?.message}
              placeholder="Ex: 10.1.50/24, 192.168.80.0/241"
              register={register("dns_address")}
            />
            <NetworkInput
              label="Netmask of Sensor"
              error={errors.netmask?.message}
              placeholder="Enter Netmask"
              register={register("netmask")}
            />

            <NetworkInput
              label="Gateway IP of Sensor"
              error={errors.gateway?.message}
              placeholder="Enter Gateway"
              register={register("gateway")}
            />
          </div>
        </div>

        <div className="mt-2 grid md:grid-cols-2 gap-x-20">
          <div className="flex flex-col items-center md:items-end gap-5">
            <button className="w-8/12 bg-gradient-to-r from-blue-300 opacity-50 to-primary text-white px-5 py-2 m-2 rounded-xl">
              Clear Settings
            </button>
          </div>
          <div className="flex flex-col items-center md:items-start gap-5">
            <button
              type="submit"
              className="w-8/12 bg-gradient-to-r from-blue-300 to-primary opacity-50 text-white px-5 py-2 m-2 rounded-xl"
            >
              Add Network Device
            </button>
          </div>
        </div>
       
       <WizardFormButtons/>
      </form>
    </React.Fragment>
  );
};

export default NetworkInfo;
