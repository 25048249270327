import { Link } from "react-router-dom";
import { IConfirmationKeys } from "types/components";

export const ConfirmationItems: React.FC<IConfirmationKeys> = ({
  object,
  handleClick,
  title,
}) => {
  return (
    <div className=" mx-12 border-[3px] border-dashed  border-blue-100 p-1 mb-2 cursor-pointer ">
      <p className="font-subtitle1 text-primary mb-3">{title}</p>
      <div
        onClick={handleClick}
        className="  flex flex-wrap gap-y-1 "
      >
        {Object.keys(object).map((key) => (
          <div className="w-3/12" key={key}>
            <p className=" text-secondary_skyblue font-subtitle2">
              {object[key].name}
            </p>
            <p className=" opacity-40">{object[key].value}</p>
          </div>
        ))}
      </div>
    </div>
  );
};
