import { PropsWithChildren, Suspense } from "react";
import { PERMISSIONS } from "enums/Permissions";
import { Route, Navigate } from "react-router-dom";
import SuperAdminLayout from "components/hoc/SuperAdminLayout";
import Organizations from "pages/SuperAdmin/organizationList/Organizations";
import Organization from "pages/SuperAdmin/Organization/Organization";
import Dashboard from "pages/Dashboard";
import AssetsPage from "pages/Assets/Assets";
import { getToken } from "utils/common";

const Potected: React.FC<PropsWithChildren> = ({children}) => {
  const token = getToken();
  console.log(token, !token)
  if (!token) return <Navigate to="/" />;

  return (
    <Suspense fallback={<div>Loading...</div>}>
      {children}
    </Suspense>
  );
}
const SuperAdminRoutes = (
  userPermission: PERMISSIONS[] | undefined,
  isLoadingUser: boolean
) => {
  if (isLoadingUser) {
    return [];
  }

  if (
    userPermission?.some((permission: string | string[]) =>
      permission.includes("SUPER_ADMIN")
    )
  )
    return [
      <Route path="/super_admin" element={<Potected><SuperAdminLayout /></Potected>}>
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="organizations" element={<Organizations />} />
        <Route path="organization/:orgId" element={<Organization />} />
        <Route path="assets" element={<AssetsPage />} />
      </Route>,
    ];

  return [<Route path="admin/*" element={<Navigate to={""} />} />];
};

export default SuperAdminRoutes;
