import React from "react";
import { PieChart, Pie, Cell, Label, Tooltip } from "recharts";
import { needle } from "./Needle";
import CurvedText from "react-curved-text";
interface IGaugeSection {
  name: string;
  value: number;
  color: string;
}
interface IGauge {
  sections: IGaugeSection[];
}

const sections = [
  { name: "A", value: 40, color: "#FF8859" },
  { name: "BBBBB", value: 40, color: "#FF9655" },
  { name: "CCCCC", value: 20, color: "#FFA351" },
  { name: "D", value: 20, color: "#FFB04E" },
  { name: "E", value: 20, color: "#FFBE4B" },
  { name: "F", value: 20, color: "#FFBE4B" },
];
const RADIAN = Math.PI / 180;
const cx = 150;
const cy = 200;
const iR = 100;
const oR = 150;
const value = 100;

export const Gauge: React.FC<IGauge> = ({ sections }) => {
  const startAngles = sections.map((section, index) => {
    if (index === 0) {
      return 0;
    } else {
      return sections
        .slice(0, index)
        .reduce((acc, curr) => acc + (180 * curr.value) / 100, 0);
    }
  });

  // const renderLabels = ({
  //   cx,
  //   cy,
  //   midAngle,
  //   innerRadius,
  //   outerRadius,
  //   percent,
  //   index,
  // }: {
  //   cx: any;
  //   cy: any;
  //   midAngle: any;
  //   innerRadius: any;
  //   outerRadius: any;
  //   percent: any;
  //   index: any;
  // }) => {
  //   const angle = startAngles[index];
  //   const total = startAngles.length - 1;
  //   const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  //   const x =
  //     cx +
  //     (outerRadius + 10) * Math.cos(-angle * (Math.PI / startAngles[total]));
  //   const y =
  //     cy +
  //     (outerRadius + 10) * Math.sin(-angle * (Math.PI / startAngles[total]));

  //   return (
  //     <text
  //       x={x}
  //       y={y}
  //       fill="#000000"
  //       textAnchor="start"
  //       fontSize="14px"
  //       transform={`rotate(${midAngle} ${x} ${y})`}
  //     >
  //       {sections[index].name}
  //     </text>
  //   );
  // };

  const renderLabels = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }: {
    cx: any;
    cy: any;
    midAngle: any;
    innerRadius: any;
    outerRadius: any;
    percent: any;
    index: any;
  }) => {
    const angle = startAngles[index];
    const total = startAngles.length - 1;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x =
      cx + (radius + 10) * Math.cos(-angle * (Math.PI / startAngles[total]));
    const y =
      cy + (radius + 10) * Math.sin(-angle * (Math.PI / startAngles[total]));
    return (
      <CurvedText
        text={sections[index].name}
        cx={cx}
        cy={cy}
        rx={radius * 2}
        ry={radius * 2}
        width={oR *2} 
        height={oR * 2} 
        startOffset={0} 
        reversed={false} 
        textProps={{ fill: "black", fontSize: 14 }} // Adjust text properties as needed
      />
    );
  };

  return (
    <div className=" ">
      <PieChart width={330} height={200} className="flex">
        <Pie
          dataKey="value"
          startAngle={180}
          endAngle={0}
          data={sections}
          cx={cx}
          cy={cy}
          innerRadius={iR}
          outerRadius={oR}
          fill="#8884d8"
          stroke="none"
          // label={{
          //   fill: "#000000",
          //   fontSize: "12px",
          //   offsetRadius: -1,
          //   textAnchor: 'start',
          //   amplitude: '10'
          // }}
          label={renderLabels}
          labelLine={false}
        >
          {sections.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
        {needle(value, sections, cx, cy, iR, oR, "#d0d000")}
       
        <Tooltip />
      </PieChart>
      <h1 className="text-center pr-5 text-2xl font-bold">{(value/1.8).toFixed(0)}%</h1>
    </div>
  );
};
