// import "@ag-grid-community/styles/ag-theme-quartz.css";
import { ColDef } from "ag-grid-community";
// import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { AgGridReact } from "ag-grid-react";
import React from "react";
import "./table.css";

interface ITable {
  rowData: any;
  setRowData: (value: any[]) => void;
  columnDefs: ColDef[];
  handleRowClicked?: (e: any) => void;
}
const Table: React.FC<ITable> = ({
  rowData,
  setRowData,
  columnDefs,
  handleRowClicked,
}) => {
  return (
    <div className="ag-theme-alpine w-fit ">
      <AgGridReact
        columnDefs={columnDefs}
        rowData={rowData}
        pagination={true}
        domLayout="autoHeight"
        paginationPageSize={10}
        onRowClicked={(e) => handleRowClicked && handleRowClicked(e.data)}
        gridOptions={{
          autoSizeStrategy: {
            type: "fitGridWidth",
            defaultMinWidth: 100,
            columnLimits: Object.keys(rowData[0]).map((key) => {
              return { colId: key, minWidth: 100 };
            }),
          },
        }}
      />
    </div>
  );
};


//Server Side Implementation To be used when there is an API Attached
// "use strict";

// import React, {
//   useCallback,
//   useMemo,
//   useRef,
//   useState,
//   StrictMode,
// } from "react";
// import { createRoot } from "react-dom/client";
// import { AgGridReact } from "@ag-grid-community/react";
// import "ag-grid-community/styles/ag-grid.css";
// import "ag-grid-community/styles/ag-theme-alpine.css";
// import {
//   ColDef,
//   ColGroupDef,
//   GridApi,
//   GridOptions,
//   GridReadyEvent,
//   IServerSideDatasource,
//   ModuleRegistry,
//   RowModelType,
//   createGrid,
// } from "@ag-grid-community/core";
// import { FakeServer } from "./fakeServer";
// import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
// // import { MenuModule } from "@ag-grid-enterprise/menu";
// import { ServerSideRowModelModule } from "@ag-grid-enterprise/server-side-row-model";
// import "./table.css";
// // import { IOlympicDataWithId } from "./interfaces";
// ModuleRegistry.registerModules([
//   ColumnsToolPanelModule,
//   //   MenuModule,
//   ServerSideRowModelModule,
// ]);

// interface ITable {
//   rowData?: any;
//   setRowData?: (value: any[]) => void;
// //   columnDefs?: ColDef[];
//   handleRowClicked?: (e: any) => void;
// }
// const getServerSideDatasource: (server: any) => IServerSideDatasource = (
//   server: any
// ) => {
//   return {
//     getRows: (params) => {
//       console.log("[Datasource] - rows requested by grid: ",params, params.request);
//       var response = server.getData(params.request);
//       // adding delay to simulate real server call
//       setTimeout(() => {
//         if (response.success) {
//           // call the success callback
//           params.success({
//             rowData: response.rows,
//             rowCount: response.lastRow,
//           });
//         } else {
//           // inform the grid request failed
//           params.fail();
//         }
//       }, 200);
//     },
//   };
// };

// const Table: React.FC<ITable> = ({}) => {
//   const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
//   const gridStyle = useMemo(() => ({ height: "500px", width: "100%" }), []);

//   const [columnDefs, setColumnDefs] = useState<ColDef[]>([
//     { field: "id", maxWidth: 75 },
//     { field: "athlete", minWidth: 190 },
//     { field: "age" },
//     { field: "year" },
//     { field: "gold" },
//     { field: "silver" },
//     { field: "bronze" },
//   ]);
//   const defaultColDef = useMemo<ColDef>(() => {
//     return {
//       flex: 1,
//       minWidth: 90,
//     };
//   }, []);

//   const onGridReady = useCallback((params: GridReadyEvent) => {
//     fetch("https://www.ag-grid.com/example-assets/olympic-winners.json")
//       .then((resp) => {
//         console.log("Parsing");
//         return resp.json();
//       })
//       .then((data: any) => {
//         // add id to data
//         var idSequence = 1;
//         data.forEach(function (item: any) {
//           item.id = idSequence++;
//         });
//         // setup the fake server with entire dataset
//         var fakeServer : any = new (FakeServer as any)(data) ;

//         console.log("fake Server", fakeServer);
//         // create datasource with a reference to the fake server
//         var datasource = getServerSideDatasource(fakeServer);
//         // register the datasource with the grid
//         params.api!.setGridOption("serverSideDatasource", datasource);
//       }).catch((error)=>{ console.log('error', error)});
//   }, []);

//   return (
//     <div style={containerStyle}>
//       <div style={gridStyle} className={"ag-theme-alpine"}>
//         <AgGridReact
//           columnDefs={columnDefs}
//           defaultColDef={defaultColDef}
//           rowModelType={"serverSide"}
//           pagination={true}
//           paginationPageSize={20}
//           cacheBlockSize={10}
//           onGridReady={onGridReady}
//         />
//       </div>
//     </div>
//   );
// };

export default Table;
