import { AdminNavbar } from "components/navbars/AdminNavbar";
import { AdminSideBar } from "components/sidebars/AdminSidebar";
import { PropsWithChildren } from "react";
import { Outlet } from "react-router-dom";
const SuperAdminLayout: React.FC<PropsWithChildren> = ({}) => {
  return (
    <div className="">
      <AdminNavbar/>

    <AdminSideBar/>

      <div className="p-4 sm:ml-64 mt-11">
        <Outlet />
      </div>
    </div>
  );
};

export default SuperAdminLayout;
