import { ICardContainer } from "types/components";
import { IoEnterOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";

export const CardContainer: React.FC<ICardContainer> = ({
  children,
  title,
  redirectTo,
  linkClass = "",
  containerClass="",
}) => {
  return (
    <div className={twMerge("bg-[#F4F4F9] rounded-lg p-2 xl:p-10 py-10 h-fit w-fit mt-2 relative",containerClass )}>
      <Link
        to={redirectTo}
        className={twMerge(
          "bg-blue-400 h-16 rounded-t-xl w-full  absolute top-0 left-0 align-middle flex justify-center items-center gap-2",
          linkClass
        )}
      >
        <h1 className=" text-white text-xl font-bold">{title}</h1>
        <IoEnterOutline size={25} color="white" />
      </Link>
      <div className="mt-5">{children}</div>
    </div>
  );
};
