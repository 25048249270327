import assetsImage from "assets/assets.png";
import { ImageWrapper } from "components/hoc/ImageWrapper";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ConfirmationKeys } from "types/common";
import { ConfirmationItems } from "./ConfirmationItems";
import { WizardFormButtons } from "../WizardFormButtons";
import { useWizardContext } from "context/WizardContext";

const ORG_KEYS: ConfirmationKeys = {
  COMPANY_NAME: { name: "Company Name", value: "Nearshore Design." },
  CITY: { name: "City", value: "Something here" },
  STREET_ADDRESS: { name: "Street Address", value: "Something here" },
  SUITE: { name: "Suite, PO Box, Etc", value: "Something here" },
  STATE: { name: "State / Region", value: "Something here" },
  COUNTRY: { name: "Country", value: "Something here" },
  POSTAL_CODE: { name: "Postal Code", value: "Something here" },
  TELEPHONE: { name: "Telephone", value: "Something here" },
  WEBSITE: { name: "Website", value: "Something here" },
};
const SENSOR_CONFIG_KEYS: ConfirmationKeys = {
  CIDR: { name: `CIDR's to Scan (Comma Delimited)`, value: "Something here" },
  EXCLUDED: {
    name: `Excluded IP's (Comma Delimited)`,
    value: "Something here",
  },
  IP_ADDRESS_SENSOR: { name: `IP Address of Sensor`, value: "Something here" },
  NETMASK_SENSOR: { name: `Netmask of Sensor`, value: "Something here" },
  GATEWAY_IP_SENSOR: { name: `Gateway IP of Sensor`, value: "Something here" },
};
const Confirmation: React.FC = () => {
  const { state, dispatch } = useWizardContext();
  const handleNext = () => {
    dispatch({ type: "NEXT_PAGE" });
  };

  return (
    <React.Fragment>
      <ConfirmationItems
        object={ORG_KEYS}
        title="Organization Info"
        handleClick={() => {
          dispatch({ type: "GOTO_PAGE", payload: { index: 0 } });
        }}
      />
      <ConfirmationItems
        object={SENSOR_CONFIG_KEYS}
        title="Sensor Config"
        handleClick={() =>
          dispatch({ type: "GOTO_PAGE", payload: { index: 1 } })
        }
      />

      <WizardFormButtons nextButtonProps={{ onClick: handleNext }} containerClass="!relative" />
    </React.Fragment>
  );
};

export default Confirmation;
