import React from "react";
import { FormInputProps } from "types/common";
import { twMerge } from "tailwind-merge";
import { Toggle } from "components/ui/Toggle";
const FormInput: React.FC<FormInputProps> = ({
  label,
  error,
  containerStyle,
  inputStyle,
  labelStyle,
  hasToggle,
  togleState,
  onTogleChange,
  toggleLabel,
  register,
  ...inputProps
}) => {
  const inputStyling = twMerge(
    "border rounded-md px-2 py-1 w-full shadow-[#0042e4_1px_1px_0px_0px] text-secondary_dark  placeholder:opacity-70",
    inputStyle,
    error && "border-red-500 shadow-none  "
  );
  return (
    <div className={`w-full ${containerStyle}`}>
      <div className="flex gap-2 items-center">
        <label className={` text-secondary_blue font-label ${labelStyle}`}>
          {label}
        </label>
        {hasToggle && (
          <Toggle
            onChange={onTogleChange}
            checked={togleState}
            label={toggleLabel}
          />
        )}
        {error && <span className="text-red-500 text-xs">{error}</span>}
      </div>
      <input
        {...inputProps}
        {...register}
        className={inputStyling}
      />
    </div>
  );
};

export default FormInput;
