import Wrapper from "components/hoc/Wrapper";
import { CardContainer } from "components/ui/CardContainer";
import { SensorCard } from "pages/Dashboard/Sensors/SensorCards";
import { AssetForm } from "./AssetForm/AssetForm";

interface Sensor {
  data:{
  name: string;
  tag: string;
  zones: string[];
  status: string;
  }[]
}
const sensors: Sensor = {
  data:[
  {
    name: "Sensor-alpha",
    tag: "dmz, datacenter-a",
    zones: ["Atlanta-Net-1"],
    status: "Major",
  },
  {
    name: "Sensor-alpha",
    tag: "dmz, datacenter-a",
    zones: ["Atlanta-Net-1"],
    status: "Major",
  },
  {
    name: "Sensor-alpha",
    tag: "dmz, datacenter-a",
    zones: ["Atlanta-Net-1"],
    status: "Major",
  },
  {
    name: "Sensor-alpha",
    tag: "dmz, datacenter-a",
    zones: ["Atlanta-Net-1"],
    status: "Major",
  },
  {
    name: "Sensor-alpha",
    tag: "dmz, datacenter-a",
    zones: ["Atlanta-Net-1"],
    status: "Major",
  },
  {
    name: "Sensor-alpha",
    tag: "dmz, datacenter-a",
    zones: ["Atlanta-Net-1"],
    status: "Major",
  },
  {
    name: "Sensor-alpha",
    tag: "dmz, datacenter-a",
    zones: ["Atlanta-Net-1"],
    status: "Major",
  },
  {
    name: "Sensor-alpha",
    tag: "dmz, datacenter-a",
    zones: ["Atlanta-Net-1"],
    status: "Major",
  },
  {
    name: "Sensor-alpha",
    tag: "dmz, datacenter-a",
    zones: ["Atlanta-Net-1"],
    status: "Major",
  },
  {
    name: "Sensor-alpha",
    tag: "dmz, datacenter-a",
    zones: ["Atlanta-Net-1"],
    status: "Major",
  },
  {
    name: "Sensor-alpha",
    tag: "dmz, datacenter-a",
    zones: ["Atlanta-Net-1"],
    status: "Major",
  },
  {
    name: "Sensor-alpha",
    tag: "dmz, datacenter-a",
    zones: ["Atlanta-Net-1"],
    status: "Major",
  },
  {
    name: "Sensor-alpha",
    tag: "dmz, datacenter-a",
    zones: ["Atlanta-Net-1"],
    status: "Major",
  },
  {
    name: "Sensor-alpha",
    tag: "dmz, datacenter-a",
    zones: ["Atlanta-Net-1"],
    status: "Major",
  },
  {
    name: "Sensor-alpha",
    tag: "dmz, datacenter-a",
    zones: ["Atlanta-Net-1"],
    status: "Major",
  },
  {
    name: "Sensor-alpha",
    tag: "dmz, datacenter-a",
    zones: ["Atlanta-Net-1"],
    status: "Major",
  },
  {
    name: "Sensor-alpha",
    tag: "dmz, datacenter-a",
    zones: ["Atlanta-Net-1"],
    status: "Major",
  },
]};

const columns = ["Name", "Tag", "Zones", "Status"];
const AssetsPage = () => {
  return (
    <Wrapper containerClass={'p-2 xl:bg-[#F4F4F9]'}>
      <div className=" lg:flex gap-5  rounded-xl">
        <CardContainer
          title="Assets"
          redirectTo="/super_admin/dashboard"
          linkClass={"bg-gradient-to-r from-primary to-secondary_skyblue"}
          containerClass={"!px-0 border-2 border-primary rounded-2xl h-fit"}
        >
          <SensorCard
           //@ts-ignore
            sensor={sensors}
            columns={columns}
            headerCellClass={"border-primary"}
            bodyCellClass={"border-primary"}
            containerClass={'md:w-[550px]'}
          />
        </CardContainer>

        <AssetForm />
      </div>
    </Wrapper>
  );
};

export default AssetsPage;
