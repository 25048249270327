import assetsImage from "assets/assets.png";
import { ImageWrapper } from "components/hoc/ImageWrapper";
// import useForm from "hooks/useForm";
import React from "react";
import { phoneRegex } from "utils/regex";
import { z } from "zod";
import { AssetInput } from "./AssetInput/AssetInput";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useWizardContext } from "context/WizardContext";
import { WizardFormButtons } from "../WizardFormButtons";

const postalCodeRegex = /^[A-Za-z0-9]{3,10}$/; // General alphanumeric postal code validation
const websiteRegex = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z]{2,}){1,3}(\/[a-zA-Z0-9-._~:?#[\]@!$&'()*+,;=]*)?$/;

const assetsSchema = z.object({
  company_name: z.string().min(1, "Please enter company name"),
  suite: z.string().min(1, "Please enter suite"),
  state: z.string().min(1, "Please enter state"),
  postal_code: z.string().regex(postalCodeRegex, "Invalid postal code"),
  website: z.string().regex(websiteRegex, "Invalid website"),
  street_address: z.string().min(1, "Please enter street address"),
  city: z.string().min(1, "Please enter city"),
  country: z.string().min(1, "Please enter country"),
  telephone: z.string().regex(phoneRegex, "Invalid Phone Number!"),
});

type Iassets = z.infer<typeof assetsSchema>;
const Assets: React.FC = () => {
  const navigate = useNavigate();
  const { state, dispatch } = useWizardContext();
  const { organization } = state;
  console.log('initial state',organization);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Iassets>({ resolver: zodResolver(assetsSchema), defaultValues: organization});

  const submitFunction = async (data: any) => {
    console.log('===>',data);
    dispatch({ type: "SET_ITEM", payload: {key: 'organization',value: data} });
    dispatch({type: 'NEXT_PAGE'})
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(submitFunction)}>
        <div className="flex justify-center flex-wrap m-auto gap-y-2 my-10">
          <AssetInput
            label="Company Name"
            error={errors.company_name?.message}
            placeholder="Enter company name"
            register={register("company_name")}
          />

          <AssetInput
            label="Suite"
            error={errors.suite?.message}
            placeholder="Enter suite"
            register={register("suite")}
          />

          <AssetInput
            label="Street Address"
            error={errors.street_address?.message}
            placeholder="Enter street address"
            register={register("street_address")}
          />

          <AssetInput
            label="City"
            error={errors.city?.message}
            placeholder="Enter city"
            register={register("city")}
          />

          <AssetInput
            label="State"
            error={errors.state?.message}
            placeholder="Enter state"
            register={register("state")}
          />

          <AssetInput
            label="Postal Code"
            error={errors.postal_code?.message}
            placeholder="Enter postal code"
            register={register("postal_code")}
          />

          <AssetInput
            label="Country"
            error={errors.country?.message}
            placeholder="Enter country"
            register={register("country")}
          />

          <AssetInput
            label="Website"
            error={errors.website?.message}
            placeholder="Enter website"
            register={register("website")}
          />

          <AssetInput
            label="Telephone"
            error={errors.telephone?.message}
            placeholder="Enter telephone"
            register={register("telephone")}
          />
        </div>

        <WizardFormButtons />
      </form>
    </React.Fragment>
  );
};

export default Assets;
