import { Gauge } from "components/Charts/Guage/Guage";
import { Heatmap } from "components/Charts/Heatmap/Heatmap";
import { CardContainer } from "components/ui/CardContainer";
import { useWidth } from "hooks/useWidth";

const sections = [
  { name: "AAAA", value: 40, color: "#FF8859" },
  { name: "BBBBB", value: 40, color: "#FF9655" },
  { name: "CCCCC", value: 20, color: "#FFA351" },
  { name: "D", value: 20, color: "#FFB04E" },
  { name: "E", value: 20, color: "#FFBE4B" },
  { name: "FFFFF", value: 20, color: "#FFBE4B" },
];

const heatMapData = [
  [1, 8, 15, 25, 25],
  [2, 6, 10, 20, 20],
  [3, 7, 11, 21, 13],
  [4, 8, 12, 23, 18],
  [5, 9, 13, 23, 23],
];

const xLabels = ["Rare", "Unlikely", "Moderate", "Likely", "Certain"];
const yLabels = ["Severe", "Major", "Moderate", "Minor", "Insignificant"];
export const RiskAssesment = () => {
  return (
    <div className="mt-5 mb-0">
      <h1 className=" text-blue-600 text-lg font-bold mb-2">Risk Assesment</h1>
      <div className="flex items-start lg:justify-between flex-wrap justify-center">
        <CardContainer title="Risk Heatmap" redirectTo="/super_admin/dashboard">
          <Heatmap
            data={heatMapData}
            xLabels={xLabels}
            yLabels={yLabels}
            height={37}
          />
        </CardContainer>

        <CardContainer
          title="Vulnerability Remediated"
          redirectTo="/super_admin/dashboard"
          linkClass={"bg-gradient-to-r from-green-500 to-green-400"}
        >
          <Gauge sections={sections} />
        </CardContainer>
      </div>
    </div>
  );
};
