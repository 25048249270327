import { useState } from "react";
import { useParams } from "react-router-dom";
import { IOrganization } from "types/common";
import { IUser } from "types/entity";
import { ColDef } from "ag-grid-community";
import Table from "components/Tables/Table";
import { IZone } from "types/common";
const Organization = () => {
  const { orgId } = useParams();
  const [users, setUsers] = useState<Partial<IUser>[]>([
    { id: '0', email: "email@gmail.com" },
    { id: '1', email: "email@gmail.com" },
    { id: '2', email: "email@gmail.com" },
    { id: '3', email: "email@gmail.com" },
    { id: '4', email: "email@gmail.com" },
    { id: '5', email: "email@gmail.com" },
  ]);

  const [zones, setZones] = useState<IZone[]>([
    { id: 1, orgId: 0, name: "Zone Name 1", geoLocation: "geo location 1" },
    { id: 2, orgId: 1, name: "Zone Name 2", geoLocation: "geo location 2" },
    { id: 3, orgId: 2, name: "Zone Name 3", geoLocation: "geo location 3" },
    { id: 4, orgId: 3, name: "Zone Name 4", geoLocation: "geo location 4" },
    { id: 5, orgId: 4, name: "Zone Name 5", geoLocation: "geo location 5" },
    { id: 6, orgId: 5, name: "Zone Name 6", geoLocation: "geo location 6" },
  ]);

  const organization: IOrganization = {
    orgid: `ORG-${orgId}`,
    orgname: `Organization ${orgId}`,
    orgaddressone: `Address ${orgId}`,
    orgcity: `City ${orgId}`,
    orgstate: `State ${orgId}`,
    orgzip: `ZIP ${orgId}`,
    orgweb: `www.organization${orgId}.com`,
    orgcontactemail: `contact@organization${orgId}.com`,
  };

  const userColDef: ColDef[] = [
    { headerName: "User id", field: "id" },
    { headerName: "Email", field: "email" },
  ];

  const zoneColDef: ColDef[] = [
    { headerName: "Zone Id", field: "id" },
    { headerName: "Organization ID", field: "orgId" },
    { headerName: "Name", field: "name" },
    { headerName: "Geolocation", field: "geoLocation" },
  ];

  return (
    <div>
      <h1 className="text-5xl text-primary font-bold mb-2">
        {organization.orgname}
      </h1>

      <div className=" grid   md:grid-cols-2 lg:grid-cols-3 gap-5 mb-5 pl-1">
        {Object.entries(organization).map(([key, value]) => (
          <div key={key} className="pl-1">
            <p className=" font-subtitle2 font-semibold text-secondary_blue">{key}:</p>
            <p className="">{value}</p>
          </div>
        ))}
      </div>

      <div className="m-auto justify-center grid  lg:grid-cols-2 gap-5 pl-1">
        <div>
          <h5 className="text-indigo-500 text-2xl font-semibold">
            Users in Organization
          </h5>
          <Table
            rowData={users}
            setRowData={setUsers}
            columnDefs={userColDef}
          />
        </div>
        <div>
          <h5 className="text-indigo-500 text-2xl font-semibold">Zones</h5>
          <Table
            rowData={zones}
            setRowData={setZones}
            columnDefs={zoneColDef}
          />
        </div>
      </div>
    </div>
  );
};

export default Organization;
