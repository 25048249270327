import Login from "pages/Auth/Login";
import Signup from "pages/Auth/SignUp/Signup";
import { getToken } from "utils/common";
import { Navigate } from "react-router-dom";
import { Suspense, PropsWithChildren } from "react";
import { Route } from "react-router-dom";
import { Outlet } from "react-router-dom";

const NoAuthRedirect: React.FC<PropsWithChildren> = ({children}) => {
    const token = getToken();
    console.log(token, !token)
    if (token) return <Navigate to="/super_admin/dashboard" />;
  
    return (
      <Suspense fallback={<div>Loading...</div>}>
        <Outlet/>
      </Suspense>
    );
  }

const NoAuthRoutes = ()=>{

   
    return [
        <Route path="/" element={<NoAuthRedirect/>}>
           <Route path="" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
        </Route>,
      ];

}

export default NoAuthRoutes;