import loginImage from "assets/login.png";
import FormInput from "components/functional/FormInput/FormInput";
import Wrapper from "components/hoc/Wrapper";
import Divider from "components/ui/Divider";
// import useForm from "hooks/useForm";
import React from "react";
import { z } from "zod";
import styles from "./styles.module.scss";
import { useNavigate } from "react-router-dom";
import { AppremonBrand } from "assets/svg/Icons/icons";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useLoginUser } from "services/auth/auth.query";
import { formatApiError } from "utils/common";
import { PuffLoader } from "react-spinners";

const loginSchema = z.object({
  username: z.string().email({ message: "Please enter a valid email" }),
  password: z
    .string()
    .min(6, { message: "Password must be atleast 6 letters" }),
});

type loginType = z.infer<typeof loginSchema>;
const Login: React.FC = () => {
  const navigate = useNavigate();

  const {
    register,
    formState: { errors },
    handleSubmit,
    setError,
  } = useForm<loginType>({ resolver: zodResolver(loginSchema) });
  const {mutateAsync: loginUserMutation, isPending}= useLoginUser();

  const loginUser = async (data: loginType) => {
    loginUserMutation(data).catch((err)=>{
      setError('root', {type: 'manual', message: formatApiError(err) || 'An Unexpected Error occured'})
    }
    );
  };



  return (
    <Wrapper containerClass={"p-16"}>
      <form onSubmit={handleSubmit(loginUser)}>
        <div className="grid md:grid-cols-2 gap-4 border w-10/12 border-primary m-auto rounded-lg relative">
          <div className="flex flex-col justify-center items-center gap-5 p-10 md:p-0">
            <p className=" text-primary font-title">Sign In</p>
            <Divider color="#7E9FEF" width="50%" height="2px" />

            <FormInput
              label="Email"
              error={errors.username?.message}
              containerStyle=" md:!w-1/2"
              register={register("username")}
            />
            <FormInput
              label="Password"
              type="password"
              error={errors.password?.message}
              containerStyle={" md:!w-1/2"}
              register={register("password")}
            />
            <div className="flex justify-between md:w-1/2">
              <p className="text-md text-secondary_blue">Remember me</p>
              <input
                type="checkbox"
                className={`form-checkbox h-5 w-5 !bg-red-500  input-checkbox ${styles["input-checkbox"]}`}
              />
            </div>
  
            <p className="text-red-500 text-xs h-3">{errors.root?.message}</p>
            <button
              type="submit"
              className="bg-gradient-to-r from-blue-600 to-secondary_skyblue text-white font-bold py-2 px-4 w-1/2 rounded-xl flex justify-center items-center gap-2"
            >
              <PuffLoader loading={isPending} size={25} color="white"/>
              Sign In
            </button>

            <p className=" font-body text-start md:w-1/2 text-secondary_dark">
              Don't have an account yet?{" "}
              <span
                className="text-primary hover:underline hover:cursor-pointer font-medium"
                onClick={() => {
                  navigate("/signup");
                }}
              >
                {" "}
                Sign Up
              </span>
            </p>
          </div>
          <div className="h-fit relative hidden md:block">
            <img className=" h-[80vh] w-full object-cover" src={loginImage} />

            <p className="font-subtitle1 text-3xl absolute top-[40%] text-center text-primary opacity-55">
              Welcome back to Appremon. Proactive Intelligent Remediation of
              Persistent Evolving Cyber Threats.
            </p>
          </div>

          <div className="bg-primary text-white text-center py-2 w-1/4 m-auto absolute right-[38%] bottom-[96%] rounded-lg flex justify-center">
            <AppremonBrand />
          </div>
        </div>
      </form>
    </Wrapper>
  );
};

export default Login;
