import React from "react";

interface DividerProps {
  color?: string;
  width?: string;
  height?: string;
  style?: React.CSSProperties;
  isVertical?: boolean;
}

const Divider: React.FC<DividerProps> = ({
  color = "black",
  width = "100%",
  height = "1px",
  style = {},
  isVertical = false,
}) => {
  if (isVertical) {
    return (
      <div className={`h-[400px] w-[1px] hidden md:block`} style={{backgroundColor: color, height: height,}}></div>
    );
  }
  return (
    <hr
      style={{
        backgroundColor: color,
        width: width,
        height: height,
        margin: 0,
        border: "none",
        ...style,
      }}
    />
  );
};

export default Divider;
