import React from "react";
import { ISensorTable } from "types/components";
import { twMerge } from "tailwind-merge";

export const SensorCard: React.FC<ISensorTable> = ({
  sensor,
  columns,
  headerCellClass = "",
  containerClass = "",
}) => {
  const sensorColumns = Object.keys(sensor.data[0]);
  return (
      <div className={twMerge("grid  max-h-[75vh] overflow-y-auto", `grid-cols-${sensorColumns.length}`,containerClass)}>
        {sensorColumns.map((column, index) => (
          <div
            key={index}
            className={twMerge(
              "col-span-1 px-2 py-2 flex justify-center items-center border-b-2",
              headerCellClass
            )}
          >
            {column}
          </div>
        ))}
        {sensor.data.map((sensor, index) => (
          <React.Fragment key={index}>
            {Object.entries(sensor).map(([key, value]) => (
              <div
                key={key}
                className="col-span-1 px-2 py-2 flex justify-center items-center"
              >
                {value}
              </div>
            ))}
          </React.Fragment>
        ))}
      </div>
  );
};
