// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ag-theme-alpine {
    --ag-header-height: 30px;
    --ag-header-foreground-color: white;
    --ag-header-background-color: #0042e4;
    --ag-header-cell-hover-background-color: rgb(15, 18, 103);
    --ag-header-cell-moving-background-color: rgb(80, 40, 140);
  }
  .ag-theme-alpine .ag-header {
    
  }
  .ag-theme-alpine .ag-header-group-cell {
    font-weight: normal;
    font-size: 22px;
  }
  .ag-theme-alpine .ag-header-cell {
    font-size: 18px;
  }
  

  .ag-style {
    height: 280px;
    width: 100%;
  }
  
  .vertical-middle {
    display: flex;
    align-items: center;
  }
  
  .vertical-middle img {
    display: block;
    border: 1px solid #ddd;
    border-radius: 3px;
  }`, "",{"version":3,"sources":["webpack://./src/components/Tables/table.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,mCAAmC;IACnC,qCAAqC;IACrC,yDAAyD;IACzD,0DAA0D;EAC5D;EACA;;EAEA;EACA;IACE,mBAAmB;IACnB,eAAe;EACjB;EACA;IACE,eAAe;EACjB;;;EAGA;IACE,aAAa;IACb,WAAW;EACb;;EAEA;IACE,aAAa;IACb,mBAAmB;EACrB;;EAEA;IACE,cAAc;IACd,sBAAsB;IACtB,kBAAkB;EACpB","sourcesContent":[".ag-theme-alpine {\n    --ag-header-height: 30px;\n    --ag-header-foreground-color: white;\n    --ag-header-background-color: #0042e4;\n    --ag-header-cell-hover-background-color: rgb(15, 18, 103);\n    --ag-header-cell-moving-background-color: rgb(80, 40, 140);\n  }\n  .ag-theme-alpine .ag-header {\n    \n  }\n  .ag-theme-alpine .ag-header-group-cell {\n    font-weight: normal;\n    font-size: 22px;\n  }\n  .ag-theme-alpine .ag-header-cell {\n    font-size: 18px;\n  }\n  \n\n  .ag-style {\n    height: 280px;\n    width: 100%;\n  }\n  \n  .vertical-middle {\n    display: flex;\n    align-items: center;\n  }\n  \n  .vertical-middle img {\n    display: block;\n    border: 1px solid #ddd;\n    border-radius: 3px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
