// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ag-theme-alpine {
  --ag-header-height: 30px;
  --ag-header-foreground-color: white;
  --ag-header-background-color: #0042e4;
  --ag-header-cell-hover-background-color: rgb(80, 40, 140);
  --ag-header-cell-moving-background-color: rgb(80, 40, 140);
}

.ag-theme-alpine .ag-header-group-cell {
  font-weight: normal;
  font-size: 22px;
}

.ag-theme-alpine .ag-header-cell {
  font-size: 18px;
  background-color: #0042e4;
  color: #F4F4F4;
}

.ag-header-cell:hover {
  font-size: 18px;
  background-color: #2266ef !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/SuperAdmin/organizationList/table.scss","webpack://./_variables.scss"],"names":[],"mappings":"AAEA;EACE,wBAAA;EACA,mCAAA;EACA,qCAAA;EACA,yDAAA;EACA,0DAAA;AADF;;AAMA;EACE,mBAAA;EACA,eAAA;AAHF;;AAKA;EACE,eAAA;EACA,yBAAA;EACA,cCjBgB;ADelB;;AAKA;EACE,eAAA;EACA,oCAAA;AAFF","sourcesContent":["@use \"variables\" as v;\n\n.ag-theme-alpine {\n  --ag-header-height: 30px;\n  --ag-header-foreground-color: white;\n  --ag-header-background-color: #0042e4;\n  --ag-header-cell-hover-background-color: rgb(80, 40, 140);\n  --ag-header-cell-moving-background-color: rgb(80, 40, 140);\n}\n.ag-theme-alpine .ag-header {\n  \n}\n.ag-theme-alpine .ag-header-group-cell {\n  font-weight: normal;\n  font-size: 22px;\n}\n.ag-theme-alpine .ag-header-cell {\n  font-size: 18px;\n  background-color: #0042e4;\n  color : v.$secondary-light;\n}\n\n.ag-header-cell:hover {\n  font-size: 18px;\n  background-color: v.$secondary-skyblue !important;\n}\n","$primary-color: #0042e4;\n$secondary-color: #6c757d;\n$secondary-light: #F4F4F4;\n$secondary-dark: #092c72;\n$secondary-skyblue: #2266ef;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
