import React from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { AssetInput } from "../AssetInput/AssetInput";

// Define your Zod schema
const assetsSchema = z.object({
  status: z.object({
    state: z.string().min(1),
    reason: z.string(),
    reasonTTL: z.string(),
  }),
  addresses: z.object({
    address: z.string(),
    addressType: z.string(),
    vendor: z.string(),
    hostname: z.string(),
    Name: z.string(),
    atype: z.string(),
    ports: z.string(),
  }),
  osInformation: z.object({
    accuracy: z.string(),
    name: z.string(),
    line: z.string(),
  }),
  miscellaneous: z.object({
    location: z.string(),
    tags: z.string(),
    description: z.string(),
  }),
});

// Infer the type from the Zod schema
type IAssets = z.infer<typeof assetsSchema>;

export const AssetForm: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IAssets>({
    resolver: zodResolver(assetsSchema),
  });

  const onSubmit = (data: IAssets) => {
    console.log(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="w-full py-5">
      <h1 className="text-primary text-center font-title">Asset Details</h1>
      <div className="flex justify-center  flex-wrap gap-y-5 xl:gap-x-10 my-10">
        <div className="flex flex-col gap-2">
          {/* Status Fields */}
          <h1 className="text-primary font-subtitle1">Status:</h1>
          <AssetInput
            label="Status State"
            error={errors.status?.state?.message}
            register={register("status.state")}
          />
          <AssetInput
            label="Status Reason"
            error={errors.status?.reason?.message}
            register={register("status.reason")}
          />
          <AssetInput
            label="Status Reason TTL"
            error={errors.status?.reasonTTL?.message}
            register={register("status.reasonTTL")}
          />

          {/* Adress Fields */}
          <h1 className="text-primary font-subtitle1 mt-10">Addresses:</h1>
          <AssetInput
            label="Address"
            error={errors.addresses?.address?.message}
            register={register("addresses.address")}
          />
          <AssetInput
            label="Address Type"
            error={errors.addresses?.addressType?.message}
            register={register("addresses.addressType")}
          />
          <AssetInput
            label="Vendor"
            error={errors.addresses?.vendor?.message}
            register={register("addresses.vendor")}
          />
          <AssetInput
            label="Hostname"
            error={errors.addresses?.hostname?.message}
            register={register("addresses.hostname")}
          />
          <AssetInput
            label="Name"
            error={errors.addresses?.Name?.message}
            register={register("addresses.Name")}
          />
          <AssetInput
            label="Type"
            error={errors.addresses?.atype?.message}
            register={register("addresses.atype")}
          />
          <AssetInput
            label="Ports"
            error={errors.addresses?.ports?.message}
            register={register("addresses.ports")}
          />
        </div>

        <div className="flex flex-col justify-between items-center  md:items-end">
          <div className="flex flex-col gap-2">
            {/* OS Information Fields */}
            <h1 className="text-primary font-subtitle1">OS Information:</h1>
            <AssetInput
              label="Accuracy"
              error={errors.osInformation?.accuracy?.message}
              register={register("osInformation.accuracy")}
            />
            <AssetInput
              label="Name"
              error={errors.osInformation?.name?.message}
              register={register("osInformation.name")}
            />
            <AssetInput
              label="Line"
              error={errors.osInformation?.line?.message}
              register={register("osInformation.line")}
            />

            {/* miscelaneous fields */}
            <h1 className="text-primary font-subtitle1 mt-10">
              Miscellaneous:
            </h1>
            <AssetInput
              label="location"
              error={errors.miscellaneous?.location?.message}
              register={register("miscellaneous.location")}
            />
            <AssetInput
              label="Tags"
              error={errors.miscellaneous?.tags?.message}
              register={register("miscellaneous.tags")}
            />
            <AssetInput
              label="description"
              error={errors.miscellaneous?.description?.message}
              register={register("miscellaneous.description")}
            />
          </div>

          <div className="flex justify-center gap-2">
            <button
              type="submit"
              className=" bg-primary text-white w-32 py-2 rounded-xl "
            >
              Commit
            </button>
            <button
              type="submit"
              className=" bg-primary text-white w-32 py-2 rounded-xl "
            >
              Reset
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AssetForm;
