// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_input-checkbox__PhFAc {
  accent-color: #0042e4;
}`, "",{"version":3,"sources":["webpack://./src/pages/Auth/SignUp/styles.module.scss","webpack://./_variables.scss"],"names":[],"mappings":"AAEA;EACE,qBCHc;ADEhB","sourcesContent":["@use \"variables\" as v;\n\n.input-checkbox {\n  accent-color: v.$primary-color;\n}","$primary-color: #0042e4;\n$secondary-color: #6c757d;\n$secondary-light: #F4F4F4;\n$secondary-dark: #092c72;\n$secondary-skyblue: #2266ef;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input-checkbox": `styles_input-checkbox__PhFAc`
};
export default ___CSS_LOADER_EXPORT___;
