import { twMerge } from "tailwind-merge";
import Divider from "components/ui/Divider";
import { IdashboardOverviewCard } from "types/components";
import { Link } from "react-router-dom";

export const OverviewCard: React.FC<IdashboardOverviewCard> = ({
  containerClass = "",
  score,
  title,
  redirectTo
}) => {
  return (
    <Link
      className={twMerge(
        "w-[17rem] py-5 bg-gradient-to-br from-green-600 to-green-400 rounded-xl flex flex-col justify-center items-center",
        containerClass
      )}
      to={redirectTo}
    >
      <h1 className="text-4xl text-white font-bold">{score}</h1>
      <Divider color="white" width="80%" height="1px" />
      <p className="text-white font-semibold ">{title}</p>
    </Link>
  );
};
