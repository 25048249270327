import { IInput } from "types/common";
import { twMerge } from "tailwind-merge";
export const Input: React.FC<IInput> = ({ error, className,register, ...inputProps }) => {
  const inputStyling = twMerge(
    "border rounded-md px-3 py-2 w-full shadow-[#0042e4_1px_1px_0px_0px]",
    error && "border-red-500 shadow-none  ",
    className,
  );

  return <input {...inputProps} {...register} className={inputStyling} />;
};
