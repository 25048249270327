import FormInput from "components/functional/FormInput/FormInput";
import { FormInputProps } from "types/common";
import { Toggle } from "components/ui/Toggle";
export const NetworkInput: React.FC<FormInputProps> = ({
  label,
  error,
  containerStyle,
  inputStyle,
  labelStyle,
  register,
  ...inputProps
}) => {
  return (
    <>
    <FormInput
      labelStyle={" text-primary"}
      containerStyle={"!w-11/12 md:!w-8/12 text-primary mx-4"}
      label={label}
      inputStyle={"shadow-[#0042e4_1px_1px_0px_0px]"}
      error={error}
      register={register}
      {...inputProps}
    />
    </>
  );
};
