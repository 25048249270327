import React from "react";
import { FormInputProps } from "types/common";
import { twMerge } from "tailwind-merge";
// import styles from "./styles.module.scss";
import { Toggle } from "components/ui/Toggle";
import { getFirstChildId } from "utils/common";
const FormField: React.FC<FormInputProps> = ({
  label,
  error,
  containerStyle,
  labelStyle,
  children,
}) => {
    const childId = getFirstChildId(children);
    console.log(childId)

  return (
    <div className={`w-full relative ${containerStyle}`}>
      <div className="flex gap-2 items-center">
        <label
          htmlFor={childId}
          className={`text-primary ${labelStyle}`}
        >
          {label}
        </label>
        {error && <div className="text-red-500 text-sm">{error}</div>}
      </div>
      {children}
    </div>
  );
};

export default FormField;
