import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { registerUser, loginUser, logoutUser } from "services/auth/auth.service";
const useRegisterUser = () => {
  const navigate = useNavigate();
  return useMutation({
    mutationKey: ["registerUser"],
    mutationFn: registerUser,
    onError: (error) => {},
    onSuccess(data, variables, context) {
      navigate("/wizard");
    },
  });
};

const useLoginUser = () => {
  const navigate = useNavigate();
  return useMutation({
    mutationKey: ["loginUser"],
    mutationFn: loginUser,
    onError: (error) => {},
    onSuccess(data, variables, context) {
     localStorage.setItem("access_token", data.access_token)
     localStorage.setItem("token_type", data.token_type)
      navigate("/super_admin/dashboard");
    },
  });
};

const useLogoutUser = () => {
  const navigate = useNavigate();
  return useMutation({
    mutationKey: ["logoutUser"],
    mutationFn: logoutUser,
    onError: (error) => {},
    onSuccess(data, variables, context) {
      localStorage.removeItem("access_token")
      localStorage.removeItem("token_type")
      navigate("/");
    },
  });
}

export { useRegisterUser, useLoginUser, useLogoutUser };
