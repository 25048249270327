import Axios from "axios";
import { getToken } from "utils/common";
const axios = Axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL as string,
});

axios.interceptors.request.use((config) => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = token;
  }
  return config;
});

export default axios;
